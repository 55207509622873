import "../styles/contact.scss"
import React from "react"
import { graphql } from "gatsby"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

interface Props {
  data: any
}

const ContactPage: React.FC<Props> = ({ data }) => {
  const contactData = data?.strapiContact

  return (
    <Layout>
      <SEO title="Contact" />
      <div id="page-container">
        <h1>{contactData.title}</h1>
        <Reactmarkdown
          source={contactData.body}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.GATSBY_MEDIA_BASE_URL}${uri}`
          }
        />

        <h2>
          {contactData.contactEmailTitle}
        </h2>
        <p>
          <a href={`mailto:${contactData.contactEmail}`}>
            {contactData.contactEmail}
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default ContactPage

export const CONTACT_PAGE_QUERY = graphql`
  query {
    strapiContact {
      body
      slug
      title
      contactEmailTitle
      contactEmail
    }
  }
`
